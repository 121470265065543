<template>
  <v-layout column>
    <v-flex xs12>
      <status-stats-entries
        :dataset="selectedDataset"
        @unselect="selectEntry"
        v-if="selectedDataset"
      />
      <template v-else>
        <v-card flat>
          <v-card-title>
            <h3>Übersicht der Bearbeitung</h3>
            <v-spacer />
            <company-selector />
          </v-card-title>
          <v-data-table
            :loading="loading"
            disable-initial-sort
            hide-actions
            v-bind="{ headers, items }"
          >
            <tr slot="items" slot-scope="props">
              <td>{{ props.item.year }}</td>
              <td>{{ props.item.expected }}</td>
              <td>
                <a @click="selectEntry(props.item.year, 'processed')">{{
                  props.item.processed
                }}</a>
              </td>
              <td>
                <a @click="selectEntry(props.item.year, 'unprocessed')">{{
                  props.item.unprocessed
                }}</a>
              </td>
              <td class="text-xs-right">
                {{ props.item.saldoSum | formatAmountToCurrency }}
              </td>
            </tr>
            <template slot="pageText" slot-scope="props">
              Einträge {{ props.pageStart }} bis {{ props.pageStop }} von
              insgesamt
              {{ props.itemsLength }}
            </template>
          </v-data-table>
        </v-card>
      </template>
    </v-flex>
  </v-layout>
</template>

<script>
import paymentdataApi from '@/api/paymentdataApi'
import { formatAmountToCurrency } from '@/utils/paymentUtils'
import StatusStatsEntries from '@/pages/service/StatusStatsEntries'
import CompanySelector from '@/components/CompanySelector'

export default {
  name: 'StatusStats',
  components: { CompanySelector, StatusStatsEntries },
  data() {
    return {
      loading: false,
      statsData: null,
      headers: [
        { text: 'Jahr', value: 'year', sortable: true },
        {
          text: 'Zu erwartende Bescheide',
          value: 'expected',
          sortable: true,
        },
        {
          text: 'Bearbeitete Bescheide',
          value: 'processed',
          sortable: true,
        },
        {
          text: 'Unbearbeitete Bescheide',
          value: 'unprocessed',
          sortable: true,
        },
        {
          text: 'Saldo GewSt',
          value: 'saldoSum',
          align: 'right',
          sortable: true,
        },
      ],
      selectedYear: null,
      selectedType: null,
    }
  },
  props: ['type'],
  computed: {
    selectedDataset() {
      const { year, type } = this.$route.params
      if (year && type) {
        return { year, type }
      }
      return null
    },
    companyId() {
      return this.$store.getters.currentCompany?.id
    },
    items() {
      if (this.statsData) {
        const years = Object.keys(this.statsData).map((year) => Number(year))
        years.sort((a, b) => b - a)
        return years.map((year) => {
          return {
            ...this.statsData[year],
            year,
          }
        })
      }
      return []
    },
  },
  filters: {
    formatAmountToCurrency,
  },
  methods: {
    async loadData() {
      this.loading = true
      this.statsData = await paymentdataApi.getStatusStats(this.companyId)
      this.loading = false
    },
    async selectEntry(year = null, type = null) {
      this.$router.push({
        name: 'Service.StatusStats',
        params: { year, type },
      })
    },
  },
  watch: {
    companyId: {
      handler: 'loadData',
      immediate: true,
    },
  },
}
</script>

<style scoped></style>
