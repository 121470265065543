<template>
  <v-layout column>
    <v-flex xs12>
      <v-card flat>
        <v-card-title> </v-card-title>
        <v-card-title>
          <h3>{{ heading }}</h3>
          <v-spacer />
          <v-text-field
            :value="search"
            @input="debounceSearch"
            append-icon="search"
            hide-details
            label="Suche"
            ref="search"
            single-line
          />
        </v-card-title>

        <v-data-table
          v-if="serverData"
          :disable-initial-sort="true"
          :hide-actions="pagination.totalItems === 0"
          :loading="loading"
          :pagination.sync="pagination"
          :rows-per-page-items="[15]"
          :total-items="this.serverData ? this.serverData.total : 0"
          v-bind="{ headers, items }"
        >
          <tr
            @click="
              $router.push({
                name: 'PaymentDataView',
                params: {
                  id: props.item.master_data.id,
                  year: props.item.year,
                },
              })
            "
            slot="items"
            slot-scope="props"
            style="cursor: pointer"
          >
            <td>{{ props.item.master_data.ags }}</td>
            <td>{{ props.item.master_data.municipality }}</td>
            <td class="text-xs-right">
              {{ props.item.businessTax | formatAmountToCurrency }}
            </td>
            <td class="text-xs-right">
              {{ props.item.saldoSum | formatAmountToCurrency }}
            </td>
          </tr>
          <template slot="pageText" slot-scope="props">
            Einträge {{ props.pageStart }} bis {{ props.pageStop }} von
            insgesamt
            {{ props.itemsLength }}
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { formatAmountToCurrency } from '@/utils/paymentUtils'
import paymentdataApi from '@/api/paymentdataApi'
import debounce from 'lodash/debounce'

export default {
  name: 'StatusStatsEntries',
  props: ['dataset'],
  data() {
    return {
      search: '',
      loading: false,
      rowsPerPageItems: [20],
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 10,
        totalItems: 0,
      },
      currentIndex: 1,
      serverData: null,
      headers: [
        { text: 'AGS', value: 'masterData.ags', sortable: true },
        {
          text: 'Gemeinde',
          value: 'masterData.municipality',
          sortable: true,
        },
        {
          text: 'Gewerbesteuer',
          value: 'businessTax',
          sortable: true,
          align: 'right',
        },
        {
          text: 'Saldo',
          value: 'saldoSum',
          sortable: true,
          align: 'right',
        },
      ],
    }
  },
  computed: {
    validSearchTerm() {
      if (this.search.length >= 3) {
        return this.search
      }
      return ''
    },
    items() {
      if (this.serverData) {
        return this.serverData.data
      }
      return null
    },
    heading() {
      return {
        processed: `Bearbeitete Dokumente ${this.dataset.year}`,
        unprocessed: `Unbearbeitete Dokumente ${this.dataset.year}`,
        expected: `Erwartete Dokumente ${this.dataset.year}`,
      }[this.dataset.type]
    },
  },
  methods: {
    debounceSearch: debounce(function (value) {
      this.search = value
    }, 200),
    async loadPage(page) {
      if (!this.loading) {
        try {
          this.loading = true
          const { year, type } = this.dataset
          const currentCompanyId = this.$store.getters.currentCompany.id
          const { sortBy } = this.pagination
          const descending = this.pagination.descending ? 'ASC' : 'DESC'
          this.serverData = await paymentdataApi.getStatusStatsEntries(
            currentCompanyId,
            type,
            year,
            page,
            sortBy,
            descending,
            this.validSearchTerm
          )
          this.pagination.totalItems = this.serverData.total
          this.pagination.page = this.serverData.current_page
          this.pagination.rowsPerPage = this.serverData.per_page
        } catch (error) {
          this.$root.setSnackbar('error', error.message)
          throw error
        } finally {
          this.loading = false
        }
      }
    },
  },
  watch: {
    'pagination.page': {
      handler: 'loadPage',
      immediate: true,
    },
    'pagination.sortBy': {
      handler: 'loadPage',
      immediate: true,
    },
    'pagination.descending': {
      handler: 'loadPage',
      immediate: true,
    },
    validSearchTerm() {
      this.loadPage()
    },
  },
  filters: {
    formatAmountToCurrency,
  },
  mounted() {
    this.$refs.search?.focus()
  },
}
</script>

<style scoped></style>
